import React from "react";
import { SEO, MarkdownContent, Image, DynamicForm } from "@bluefin/components";
import { Grid, Segment } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class ClassesPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      fishermanWebsiteComponent,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          title={fishermanBusinessWebsitePage.seoTitle}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          ogUrl={"https://www.designerae.com/classes/"}
        />
        <Grid
          className={"custom-page-loc-classes custom-page-grid"}
          style={{}}
          stackable={true}
        >
          <Grid.Row
            className={"sjhloiijvriqrcss"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
            columns={1}
          >
            <Grid.Column
              className={"gllfvotyrllatgdv"}
              style={{ padding: 16 }}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "e67f8184-7fc7-4eec-ac49-942208c0374b",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"khrxzesatiybpfgv"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"cigzskrzspvynjry"}
              style={{ padding: 16 }}
              width={7}
              textAlign={"center"}
              verticalAlign={"top"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{}}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "ec71afb3-14e6-4bb2-a8c1-9e13f0f12759",
                    numToSelect: 1,
                  })}
                  background={false}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"pvlupuqjbgyilbbc"}
              style={{ padding: 16 }}
              width={9}
              textAlign={"justified"}
              verticalAlign={"top"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "00c6dc6d-38f0-436d-b89c-f9653b306102",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"qpkmqepcbonzgpbb"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
            columns={1}
          >
            <Grid.Column
              className={"celavecmpifngkal"}
              style={{ padding: 16 }}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "02ad8df1-aa69-4d02-9085-25cc46fa9501",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"wdlwkgnxwwoudclx"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"top"}
            columns={3}
          >
            <Grid.Column
              className={"xrwaqsvmrekyrtim"}
              style={{ padding: 16 }}
              textAlign={"center"}
              verticalAlign={"top"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "675e67c0-6e85-4120-8b71-e07d1c7c45b9",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"tgtyvddvkqtwnewg"}
              style={{ padding: 16 }}
              textAlign={"center"}
              verticalAlign={"top"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "0fa63a76-6ec4-4774-be93-51378e87cf4a",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"ejjubulcckuqcdpv"}
              style={{ padding: 16 }}
              textAlign={"center"}
              verticalAlign={"top"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "e2e950e3-ffb7-495c-a1b1-d51d4dc5a609",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"xejqyxrbwhgypjot"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"top"}
            columns={1}
          >
            <Grid.Column
              className={"ujiuumublkxtkoxq"}
              style={{ padding: 16 }}
              textAlign={"center"}
              verticalAlign={"top"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "2dc643f8-ba8f-48d3-8c44-a0a819dc5121",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"tiofbpfaylbnpfow"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"top"}
          >
            <Grid.Column
              className={"zqievauzxarczpix"}
              style={{ padding: 16 }}
              width={9}
              textAlign={"justified"}
              verticalAlign={"top"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "e5050954-5f00-472f-ade5-d86baa40a2cd",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"yrmppsqlsgyubpob"}
              style={{ padding: 16 }}
              width={7}
              textAlign={"center"}
              verticalAlign={"top"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{}}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "aa993a4e-9ba2-4cfb-ade8-89959f03ccc7",
                    numToSelect: 1,
                  })}
                  background={false}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"ncbdrxxtfeurcokh"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"top"}
            columns={1}
          >
            <Grid.Column
              className={"evfopbckycantjib"}
              style={{ padding: 16 }}
              textAlign={"center"}
              verticalAlign={"top"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "e8f12670-6180-4aa2-b263-d0a10a769129",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"dqvrtjsjmdimhnpe"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"kigdfiiorbjyolbh"}
              style={{ padding: 16 }}
              width={7}
              textAlign={"left"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "657c8311-02ad-46f0-bebf-f158d3e18ab7",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"hgyawecvqrbzguao"}
              style={{ padding: 16 }}
              width={7}
              textAlign={"left"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "6cf01ca3-5135-4169-8882-486751919c15",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"jtenxvmgldaggogw"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"vzpgwpwcpyhxbxez"}
              style={{ padding: 16 }}
              width={7}
              textAlign={"left"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "878d156c-4477-40a1-9d50-7de6a5c55c9b",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"mildbwvlunxizqrn"}
              style={{ padding: 16 }}
              width={7}
              textAlign={"left"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "8b01601a-7d1a-4c4d-8aba-c5ab7bd5647a",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"hjvtybzhpdhzzvor"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"top"}
            columns={1}
          >
            <Grid.Column
              className={"uncnwtivmjgdzpfb"}
              style={{ padding: 16 }}
              textAlign={"center"}
              verticalAlign={"top"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "a3e7edce-4169-476f-8a8f-33c70884c0f4",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"xyxadhfjdyrqsikz"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"top"}
          >
            <Grid.Column
              className={"sidqxullylfxrpzb"}
              style={{ padding: 16 }}
              width={10}
              textAlign={"left"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <DynamicForm
                  className={""}
                  style={{}}
                  businessId={1933}
                  businessInfoId={7093}
                  websiteId={8338}
                  locationId={2030}
                  componentConfigurationId={72139}
                  header={fishermanWebsiteComponent.data.header}
                  fields={fishermanWebsiteComponent.data.dynamicFormFields}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 27098 }) {
      title
      seoTitle
      description
      components {
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        order
        fastId
        componentIdentifier
        contentNodes {
          content
        }
      }
    }
    fishermanWebsiteComponent(
      fastId: { eq: "CustomForm" }
      componentIdentifier: { eq: "42933be0-1493-4d2d-8dd3-3d7d07b8b030" }
    ) {
      data {
        header
        dynamicFormFields {
          name
          label
          options {
            label
            value
          }
          required
          inputType
          widgetType
          placeholder
        }
      }
    }
  }
`;
